import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    className: string;
}

export const Contents: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const list = t("privacy.list").split(";");

    const renderLi = (item: string) => (
        <li className={className} key={item}>
            {item}
        </li>
    );

    return <ul className={twMerge("list-disc list-inside mt-3")}>{list.map(renderLi)}</ul>;
};
