import { FC } from "react";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "./button.component";
import { ManageAccountButtonsProps } from "types";
import { manageAccountButtons } from "constants/index";

interface Props {
    listClassName?: string;
    itemClassName?: string;
    buttonClassName?: string;
    onButtonClick?: () => void;
}

export const ManageAccountList: FC<Props> = ({
    listClassName,
    itemClassName,
    buttonClassName,
    onButtonClick
}) => {
    const navigate = useNavigate();

    const { t } = useTranslation();

    const renderManageAccountButton = ({ name, path, type }: ManageAccountButtonsProps) => {
        const handleClick = () => {
            onButtonClick?.();
            navigate(path);
        };

        return (
            <li aria-label={name} key={name} className={itemClassName}>
                <Button
                    buttonType={type}
                    className={twMerge("px-5", buttonClassName)}
                    onClick={handleClick}>
                    {t(name)}
                </Button>
            </li>
        );
    };

    return (
        <ul aria-label="Manage account buttons" className={twMerge("list-none", listClassName)}>
            {manageAccountButtons.map(renderManageAccountButton)}
        </ul>
    );
};
