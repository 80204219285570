import { ArrowIcon } from "assets/icons/arrow.icon";
import { PowerIcon } from "assets/icons/power.icon";
import { FC } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
    className?: string;
}

export const OffItem: FC<Props> = ({ className }) => {
    return (
        <div
            className={twMerge(
                "relative bg-gradient-to-tr from-stone-900 via-stone-700 to-stone-900 p-[1px] rounded-full",
                className
            )}>
            <div className="bg-gradient-to-b from-stone-900 to-main-black h-full rounded-full p-1 overflow-hidden flex items-center">
                <PowerIcon className="lg:w-6" />
                <ArrowIcon className="lg:w-7" />
                <span className="font-medium text-base pr-3 lg:text-2">OFF</span>
            </div>
        </div>
    );
};
