import { FC, SVGProps } from "react";

export const CustomStarIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg" width={27} {...props}>
        <path
            d="M1.99999 12.0417C7.73761 15.7272 10.8826 14.9615 14.8503 12.0417C17.5821 10.0313 19.2238 7.1295 20.4784 4.00203C20.5105 3.92218 21.4023 2.23489 20.9071 2.04889C20.257 1.80467 19.3326 2.5302 18.9834 3.0141C18.0731 4.27566 18.2253 6.32518 18.7196 7.70391C19.7949 10.7032 22.2246 13.498 25.0953 14.7443C25.9753 15.1264 26.2068 15.0736 26.986 15.0736C27.0556 15.0736 26.8468 15.0736 26.7771 15.0736C24.5165 15.0736 22.6338 14.9221 21.3029 17.1176C18.8975 21.0856 17.3294 25.78 17.0817 30.4489C17.064 30.7835 17.1593 33.1993 16.9938 31.2438C16.7622 28.507 16.0998 25.8575 14.8612 23.4085C12.0179 17.7865 7.31455 15.1743 2 12.6208"
            stroke="#FA4E04"
            strokeWidth="3"
            strokeLinecap="round"
        />
    </svg>
);
