import { FC, SVGProps } from "react";

export const UploadIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg" width={11} {...props}>
        <path
            d="M4.99821 8.45947L4.99821 3.02614L3.26488 4.75947L2.33154 3.79281L5.66488 0.459473L8.99821 3.79281L8.06488 4.75947L6.33154 3.02614L6.33154 8.45947L4.99821 8.45947ZM1.66488 11.1261C1.29821 11.1261 0.984432 10.9957 0.723543 10.7348C0.462654 10.4739 0.331987 10.1599 0.331543 9.79281L0.331543 7.79281L1.66488 7.79281L1.66488 9.79281L9.66488 9.79281L9.66488 7.79281L10.9982 7.79281L10.9982 9.79281C10.9982 10.1595 10.8678 10.4735 10.6069 10.7348C10.346 10.9961 10.032 11.1266 9.66488 11.1261L1.66488 11.1261Z"
            fill="#FFFCFF"
        />
    </svg>
);
