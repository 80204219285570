import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Slide } from "react-awesome-reveal";
import { WhatOfferItem } from "./common/what-offer-item.component";
import { EarthIcon } from "assets/icons/earth.icon";
import { GeoIcon } from "assets/icons/geo.icon";
import { SpeedIcon } from "assets/icons/speed.icon";
import { SectionId } from "enums/index";

const whatArray = [
    {
        header: "globalWebsites",
        subtext: "blocked",
        icon: <EarthIcon />
    },
    {
        header: "fastSpeed",
        subtext: "faster",
        icon: <SpeedIcon />
    },
    {
        header: "servers",
        subtext: "streaming",
        icon: <GeoIcon />
    }
];

interface Item {
    icon: JSX.Element;
    header: string;
    subtext: string;
}

export const WhatWeOffer: FC = () => {
    const { t } = useTranslation();

    const renderLi = ({ icon, header, subtext }: Item) => (
        <WhatOfferItem
            icon={icon}
            header={t(`whatWeOffer.${header}`)}
            subtext={t(`whatWeOffer.${subtext}`)}
            key={header}
        />
    );

    return (
        <section
            id={SectionId.WHYWE}
            aria-label={t("whatWeOffer.header")}
            className="w-full bg-cod-gray py-20 px-9 mt-2 flex flex-col items-center justify-center lg:px-4">
            <Slide triggerOnce>
                <div className="max-w-360">
                    <h1 className="text-5xl mb-10 font-medium lg:text-center">
                        <Trans
                            i18nKey="whatWeOffer.header"
                            components={{ italic: <i className="font-thin" /> }}
                        />
                    </h1>
                    <ul className="flex justify-between lg:flex-col">{whatArray.map(renderLi)}</ul>
                </div>
            </Slide>
        </section>
    );
};
