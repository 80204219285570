import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Zoom } from "react-awesome-reveal";
import { Button } from "./common/button.component";

import { useNavigate } from "react-router-dom";
import { ServiceIcon } from "assets/icons/service.icon";
import { ButtonType } from "enums/index";
import { XMarkServiceIcon } from "assets/icons/xmark-service.icon";

export const ServiceNotAvailable: FC = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/");
    };

    return (
        <section
            aria-label="Service Not Available"
            className="w-full flex flex-col items-center text-center py-36 md:py-20 bg-header bg-no-repeat bg-cover bg-center">
            <Zoom triggerOnce>
                <ServiceIcon className="w-96 md:w-80" />
                <h1 className="text-5xl font-bold mt-8 mb-2 md:text-2xl relative">
                    <Trans
                        i18nKey={"serviceNotAvailable.service"}
                        components={{ italic: <i className="font-thin" /> }}
                    />
                    <XMarkServiceIcon className="absolute top-0 -right-7" />
                </h1>
                <p className="text-xl text-opacity-60 mb-10 md:text-sm">
                    <Trans i18nKey="serviceNotAvailable.sorry" />
                </p>
                <Button onClick={handleClick} buttonType={ButtonType.ORANGE}>
                    {t("serviceNotAvailable.previous")}
                </Button>
            </Zoom>
        </section>
    );
};
