import { FC, SVGProps } from "react";

export const EarthIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" width={40} {...props}>
        <rect width="40" height="40" rx="8" fill="#FA4E04" />
        <path
            d="M20 30C18.6167 30 17.3167 29.7373 16.1 29.212C14.8833 28.6867 13.825 27.9743 12.925 27.075C12.025 26.1757 11.3127 25.1173 10.788 23.9C10.2633 22.6827 10.0007 21.3827 10 20C9.99933 18.6173 10.262 17.3173 10.788 16.1C11.314 14.8827 12.0263 13.8243 12.925 12.925C13.8237 12.0257 14.882 11.3133 16.1 10.788C17.318 10.2627 18.618 10 20 10C21.382 10 22.682 10.2627 23.9 10.788C25.118 11.3133 26.1763 12.0257 27.075 12.925C27.9737 13.8243 28.6863 14.8827 29.213 16.1C29.7397 17.3173 30.002 18.6173 30 20C29.998 21.3827 29.7353 22.6827 29.212 23.9C28.6887 25.1173 27.9763 26.1757 27.075 27.075C26.1737 27.9743 25.1153 28.687 23.9 29.213C22.6847 29.739 21.3847 30.0013 20 30ZM20 28C22.2333 28 24.125 27.225 25.675 25.675C27.225 24.125 28 22.2333 28 20C28 19.8833 27.996 19.7623 27.988 19.637C27.98 19.5117 27.9757 19.4077 27.975 19.325C27.8917 19.8083 27.6667 20.2083 27.3 20.525C26.9333 20.8417 26.5 21 26 21H24C23.45 21 22.9793 20.8043 22.588 20.413C22.1967 20.0217 22.0007 19.5507 22 19V18H18V16C18 15.45 18.196 14.9793 18.588 14.588C18.98 14.1967 19.4507 14.0007 20 14H21C21 13.6167 21.1043 13.2793 21.313 12.988C21.5217 12.6967 21.7757 12.459 22.075 12.275C21.7417 12.1917 21.4043 12.125 21.063 12.075C20.7217 12.025 20.3673 12 20 12C17.7667 12 15.875 12.775 14.325 14.325C12.775 15.875 12 17.7667 12 20H17C18.1 20 19.0417 20.3917 19.825 21.175C20.6083 21.9583 21 22.9 21 24V25H18V27.75C18.3333 27.8333 18.6627 27.896 18.988 27.938C19.3133 27.98 19.6507 28.0007 20 28Z"
            fill="white"
        />
    </svg>
);
