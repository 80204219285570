import { RouteObject } from "react-router-dom";
import { Home } from "pages/home.page";

import { TERMS, PRIVACY_POLICY, HOME_PATH, LOGIN_PATH, PAYMENT } from "./constants";

import { MainLayout } from "components/main-layout.component";

import ErrorPage from "pages/error-page";
import { LoginPage } from "pages/login.page";
import { PaymentPage } from "pages/payment-page";
import { TermsOfService } from "pages/terms-of-service.page";
import { PrivacyPolicy } from "pages/privacy-policy.page";

export const routes: RouteObject[] = [
    {
        path: HOME_PATH,
        element: <MainLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: HOME_PATH,
                element: <Home />
            },
            {
                path: LOGIN_PATH,
                element: <LoginPage />
            },
            {
                path: TERMS,
                element: <TermsOfService />
            },
            {
                path: PRIVACY_POLICY,
                element: <PrivacyPolicy />
            },
            {
                path: PAYMENT,
                element: <PaymentPage />
            }
        ]
    }
];
