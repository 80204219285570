import { FC } from "react";
import { Header } from "components/header.component";
import { WhatWeOffer } from "components/what-we-offer.component";
import { WhatMakes } from "components/what-makes.component";
import { Pricing } from "components/pricing.component";
import { FAQ } from "components/faq.component";

export const Home: FC = () => {
    return (
        <>
            <Header />
            <main className="flex flex-col items-center justify-center">
                <WhatWeOffer />
                <Pricing />
                <WhatMakes />
                <FAQ />
            </main>
        </>
    );
};
