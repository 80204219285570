import { FC, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";
import { ButtonToTop } from "./button-to-top.component";

interface Props extends PropsWithChildren {
    className?: string;
    classNameHeader?: string;
    header: string;
}

export const InfoWrapper: FC<Props> = ({ className, classNameHeader, header, children }) => {
    return (
        <>
            <main
                className={twMerge(
                    "w-full flex flex-col bg-header bg-no-repeat bg-contain items-center pt-28 lg:px-4 lg:pb-0 lg:pt-20",
                    className
                )}>
                <div className="max-w-360 px-10 lg:px-4">
                    <nav className="flex items-center list-none mb-3 text-opacity-40">
                        <li className="text-opacity-40 text-base hover:text-opacity-100 transition-all">
                            <a href="/">Homepage</a>
                        </li>
                        &nbsp;/&nbsp;
                        <li>{header}</li>
                    </nav>
                    <h1
                        className={twMerge(
                            "text-5xl font-semibold mb-8 lg:text-3xl",
                            classNameHeader
                        )}>
                        {header}
                    </h1>
                    <section aria-label={header} className="w-9/12 relative lg:w-full">
                        {children}
                    </section>
                    <ButtonToTop />
                </div>
            </main>
        </>
    );
};
