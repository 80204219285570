import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import {
    microsoftLink,
    sentryPrivacy,
    firebasePrivacy,
    appleCash,
    apple,
    google
} from "constants/index";

interface Props {
    className: string;
}

export const Aggregate: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const list = t("privacy.accessed").split(";");

    const renderLi = (item: string) => (
        <li className={className} key={item}>
            {item}
        </li>
    );

    return (
        <>
            <p className={twMerge("mt-3", className)}>{t("privacy.browsing")}</p>
            <ul className={twMerge("list-disc list-inside", className)}>{list.map(renderLi)}</ul>
            <p className={twMerge("mb-3", className)}>{t("privacy.compel")}</p>
            <h2 className="font-bold text-base mb-2">{t("privacy.versions")}</h2>
            <p className={twMerge("mb-3", className)}>{t("privacy.activated")}</p>
            <h2 className="font-bold text-base mb-2">{t("privacy.successful")}</h2>
            <p className={twMerge("mb-3", className)}>{t("privacy.whether")}</p>
            <h2 className="font-bold text-base mb-2">{t("privacy.transferred")}</h2>
            <p className={twMerge("mb-3", className)}>{t("privacy.regarding")}</p>
            <h2 className="font-bold text-base mb-2">{t("privacy.summary")}</h2>
            <p className={twMerge("mb-3", className)}>{t("privacy.minimalUsage")}</p>
            <p className={twMerge("mb-3", className)}>{t("privacy.engineered")}</p>
            <h2 className="font-bold text-base mb-2">{t("privacy.appDiagnostics")}</h2>
            <p className={twMerge("mb-3", className)}>{t("privacy.permission")}</p>
            <p className={className}>{t("privacy.shareInfo")}</p>
            <ul className={twMerge("list-inside list-disc", className)}>
                <li className={className}>{t("privacy.diagnostic")}</li>
                <li className={className}>{t("privacy.speedTest")}</li>
                <li className={className}>{t("privacy.including")}</li>
                <li className={className}>
                    <Trans
                        i18nKey="privacy.owned"
                        components={{
                            microsoftLink: (
                                <a
                                    aria-label="Microsoft Privacy Link"
                                    href={microsoftLink}
                                    target="_blank"
                                    className="text-primary underline"
                                    rel="noreferrer noopener"
                                />
                            ),
                            sentryPrivacy: (
                                <a
                                    aria-label="Sentry Privacy Link"
                                    href={sentryPrivacy}
                                    target="_blank"
                                    className="text-primary underline"
                                    rel="noreferrer noopener"
                                />
                            )
                        }}
                    />
                </li>
                <li className={className}>
                    <Trans
                        i18nKey="privacy.software"
                        components={{
                            firebasePrivacy: (
                                <a
                                    aria-label="Firebase Link"
                                    href={firebasePrivacy}
                                    target="_blank"
                                    className="text-primary underline"
                                    rel="noreferrer noopener"
                                />
                            ),
                            sentryPrivacy: (
                                <a
                                    aria-label="Sentry Privacy Link"
                                    href={sentryPrivacy}
                                    target="_blank"
                                    className="text-primary underline"
                                    rel="noreferrer noopener"
                                />
                            )
                        }}
                    />
                </li>
                <li className={className}>
                    <Trans
                        i18nKey="privacy.sentry"
                        components={{
                            sentryPrivacy: (
                                <a
                                    aria-label="Sentry Privacy Link"
                                    href={sentryPrivacy}
                                    target="_blank"
                                    className="text-primary underline"
                                    rel="noreferrer noopener"
                                />
                            )
                        }}
                    />
                </li>
                <li className={className}>
                    <Trans
                        i18nKey="privacy.apple"
                        components={{
                            apple: (
                                <a
                                    aria-label="Apple link"
                                    href={apple}
                                    target="_blank"
                                    className="text-primary underline"
                                    rel="noreferrer noopener"
                                />
                            ),
                            firebase: (
                                <a
                                    aria-label="Firebase link"
                                    href={firebasePrivacy}
                                    target="_blank"
                                    className="text-primary underline"
                                    rel="noreferrer noopener"
                                />
                            ),
                            appleCash: (
                                <a
                                    aria-label="Apple Crash link"
                                    href={appleCash}
                                    target="_blank"
                                    className="text-primary underline"
                                    rel="noreferrer noopener"
                                />
                            )
                        }}
                    />
                </li>
                <li className={className}>
                    <Trans
                        i18nKey="privacy.firebase"
                        components={{
                            firebase: (
                                <a
                                    aria-label="Firebase Link"
                                    href={firebasePrivacy}
                                    target="_blank"
                                    className="text-primary underline"
                                    rel="noreferrer noopener"
                                />
                            )
                        }}
                    />
                </li>
                <li className={twMerge("mb-4", className)}>
                    <Trans
                        i18nKey="privacy.google"
                        components={{
                            google: (
                                <a
                                    aria-label="Google link"
                                    href={google}
                                    target="_blank"
                                    className="text-primary underline"
                                    rel="noreferrer noopener"
                                />
                            )
                        }}
                    />
                </li>
                <p className={twMerge("mb-3", className)}>
                    <Trans
                        i18nKey="privacy.activation"
                        components={{
                            appleCrash: (
                                <a
                                    aria-label="Apple cash"
                                    href={appleCash}
                                    target="_blank"
                                    className="text-primary underline"
                                    rel="noreferrer noopener"
                                />
                            )
                        }}
                    />
                </p>
                <h2 className="font-bold text-base mb-2">{t("privacy.mediaStreamer")}</h2>
                <p className={twMerge("mb-3", className)}>{t("privacy.otherDevices")}</p>
                <p className={twMerge("mb-3", className)}>{t("privacy.notWish")}</p>
                <h2 className="font-bold text-base mb-2">{t("privacy.jurisdiction")}</h2>
                <p className={twMerge("mb-3", className)}>{t("privacy.coreMission")}</p>
                <p className={twMerge("mb-3", className)}>{t("privacy.retention")}</p>
                <h2 className="font-bold text-base mb-2">{t("privacy.informationRelated")}</h2>
                <p className={twMerge("mb-3", className)}>{t("privacy.correspondence")}</p>
                <p className={twMerge("mb-3", className)}>{t("privacy.platforms")}</p>
                <h2 className="font-bold text-base mb-2">{t("privacy.measures")}</h2>
                <p className={twMerge("mb-3", className)}>{t("privacy.physical")}</p>
                <p className={twMerge("mb-3", className)}>{t("privacy.systems")}</p>
                <p className={twMerge("mb-3", className)}>{t("privacy.housed")}</p>
            </ul>
        </>
    );
};
