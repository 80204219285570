import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    className: string;
}

interface Item {
    title: string;
    text: string;
}

const list: Item[] = [
    {
        title: "related",
        text: "information"
    },
    {
        title: "connection",
        text: "minimal"
    },
    {
        title: "diagnostics",
        text: "usability"
    },
    {
        title: "choose",
        text: "provide"
    }
];

export const GeneralInfo: FC<Props> = ({ className }) => {
    const { t } = useTranslation();

    const renderLi = ({ title, text }: Item) => (
        <li className={twMerge("mb-4", className)} key={title}>
            <h2 className="text-base font-bold mb-2">{t(`privacy.${title}`)}</h2>
            <p className={className}>{t(`privacy.${text}`)}</p>
        </li>
    );

    return (
        <>
            <p className={twMerge("my-3", className)}>{t("privacy.collects")}</p>
            <ul className={"list-none"}>{list.map(renderLi)}</ul>
        </>
    );
};
