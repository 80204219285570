import { FC } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
    className?: string;
    icon: JSX.Element;
    header: string;
    subtext: string;
}

export const WhatOfferItem: FC<Props> = ({ className, icon, header, subtext }) => {
    return (
        <li className={twMerge("w-87/3 lg:w-full lg:mb-10 lg:last:mb-0", className)}>
            {icon}
            <h2 className="text-lg mb-2 font-medium mt-6">{header}</h2>
            <p className="text-base text-opacity-60">{subtext}</p>
        </li>
    );
};
