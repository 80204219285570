import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Zoom } from "react-awesome-reveal";
import { SectionId } from "enums/index";
import { CustomStarIcon } from "assets/icons/custom-star.icon";
import { PricingItem } from "./common/pricing-item.component";
import FirstImage from "assets/images/plan-1.png";
import SecondImage from "assets/images/plan-2.png";
import ThirdImage from "assets/images/plan-3.png";
import FourthImage from "assets/images/plan-4.png";
import { useNavigate } from "react-router-dom";
import { PAYMENT } from "router/constants";

const pricingArray = [
    {
        image: FirstImage,
        days: 30,
        price: 24.99,
        href: "/?packId=2&pr=16"
    },
    {
        image: SecondImage,
        days: 60,
        price: 24.99,
        href: "/?packId=3&pr=16"
    },
    {
        image: ThirdImage,
        days: 180,
        price: 24.99,
        href: "/?packId=4&pr=16"
    },
    {
        image: FourthImage,
        days: 320,
        price: 24.99,
        href: "/?packId=5&pr=16"
    }
];

interface Item {
    image: string;
    days: number;
    price: number;
    href: string;
}

export const Pricing: FC = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const onSubscribeButtonClick = (href: string) => navigate(PAYMENT + href);

    const renderList = (item: Item) => (
        <PricingItem
            {...item}
            key={item.days}
            onButtonClick={() => onSubscribeButtonClick(item.href)}
        />
    );

    return (
        <section
            aria-label={t("choosePlan.header")}
            id={SectionId.PRICING}
            className="w-full py-20 px-9 mt-2 flex flex-col items-center justify-center lg:px-4 max-w-360">
            <Zoom triggerOnce>
                <h1 className="text-5xl font-semibold relative mb-8 lg:text-4xl lg:text-center">
                    <Trans
                        i18nKey="choosePlan.header"
                        components={{ italic: <i className="font-thin" /> }}
                    />
                    <CustomStarIcon className="absolute -right-7 -top-3 lg:-right-4" />
                </h1>
                <ul className="w-full flex justify-between flex-wrap lg:gap-y-4 sm:flex-col">
                    {pricingArray.map(renderList)}
                </ul>
            </Zoom>
        </section>
    );
};
