import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { Slide } from "react-awesome-reveal";

import { InfoWrapper } from "components/common/info-wrapper.component";
import { Acceptance } from "components/terms/acceptance.component";
import { Modification } from "components/terms/modification.component";
import { Privacy } from "components/terms/privacy.component";
import { Refund } from "components/terms/refund.component";
import { Acceptable } from "components/terms/acceptable.component";
import { License } from "components/terms/license.component";
import { Language } from "components/terms/language.component";
import { Websites } from "components/terms/websites.component";
import { Partner } from "components/terms/partner.component";
import { Disclaimers } from "components/terms/disclaimers.component";
import { Limitations } from "components/terms/limitations.component";
import { Indemnification } from "components/terms/indemnification.component";
import { Choice } from "components/terms/choice.component";
import { Arbitration } from "components/terms/arbitration.component";
import { Assignment } from "components/terms/assignment.component";
import { Provisions } from "components/terms/provisions.component";
import { Payment } from "components/terms/payment.component";

interface LiItem {
    header: string;
    content: JSX.Element;
}

export const TermsOfService: FC = () => {
    const { t } = useTranslation();

    const defaultStyles = "text-base text-opacity-60 font-normal leading-6";

    const termsItems = [
        {
            header: "acceptance",
            content: <Acceptance className={defaultStyles} />
        },
        {
            header: "modification",
            content: <Modification className={defaultStyles} />
        },
        {
            header: "privacy",
            content: <Privacy className={defaultStyles} />
        },
        {
            header: "refund",
            content: <Refund className={defaultStyles} />
        },
        {
            header: "acceptable",
            content: <Acceptable className={defaultStyles} />
        },
        {
            header: "license",
            content: <License className={defaultStyles} />
        },
        {
            header: "language",
            content: <Language className={defaultStyles} />
        },
        {
            header: "websites",
            content: <Websites className={defaultStyles} />
        },
        {
            header: "partner",
            content: <Partner className={defaultStyles} />
        },
        {
            header: "disclaimers",
            content: <Disclaimers className={defaultStyles} />
        },
        {
            header: "limitations",
            content: <Limitations className={defaultStyles} />
        },
        {
            header: "indemnification",
            content: <Indemnification className={defaultStyles} />
        },
        {
            header: "choice",
            content: <Choice className={defaultStyles} />
        },
        {
            header: "arbitration",
            content: <Arbitration className={defaultStyles} />
        },
        {
            header: "assignment",
            content: <Assignment className={defaultStyles} />
        },
        {
            header: "provisions",
            content: <Provisions className={defaultStyles} />
        },
        {
            header: "payment",
            content: <Payment className={defaultStyles} />
        }
    ];

    const renderLi = ({ header, content }: LiItem) => (
        <Slide triggerOnce key={header}>
            <li className="font-bold text-base mb-3">
                {t(`terms.${header}`)}
                {content}
            </li>
        </Slide>
    );
    return (
        <InfoWrapper header={t("terms.header")}>
            <p className={twMerge(defaultStyles, "mb-8")}>{t("terms.document")}</p>
            <p className="font-bold text-xl mb-4">{t("terms.charges")}</p>
            <ul className="list-decimal list-inside">{termsItems.map(renderLi)}</ul>
        </InfoWrapper>
    );
};
