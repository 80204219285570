import { FC, useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Zoom } from "react-awesome-reveal";
import { SectionId } from "enums/index";
import { FaqArrowIcon } from "assets/icons/faq-arrow.icon";
import { Collapse } from "./common/collapse.component";
import { twMerge } from "tailwind-merge";
import { supportLink } from "constants/index";

interface FaqDataContent {
    id: string;
    title: string;
    content: JSX.Element;
}

export const FAQ: FC = () => {
    const { t } = useTranslation();
    const firstList = t("faq.first.replaces").split(";");
    const textStyle = "text-sm opacity-60";

    const renderQuestion = ({ id, title, content }: FaqDataContent) => (
        <li key={id} className="border-b border-white border-opacity-10 px-8 py-6">
            <Collapse title={title} content={content} />
        </li>
    );

    const renderLi = (item: string) => (
        <li key={item} className={textStyle}>
            {item}
        </li>
    );

    const faqData: FaqDataContent[] = useMemo(
        () => [
            {
                id: "1",
                title: "faq.first.title",
                content: (
                    <>
                        <h2 className={twMerge(textStyle, "mb-5")}>{t("faq.first.encoded")}</h2>
                        <ul className="list-decimal list-inside">{firstList.map(renderLi)}</ul>
                    </>
                )
            },
            {
                id: "2",
                title: "faq.second.title",
                content: (
                    <p className={textStyle}>
                        <Trans i18nKey="myKey">{t("faq.second.recommended")}</Trans>
                    </p>
                )
            },
            {
                id: "3",
                title: "faq.third.title",
                content: (
                    <>
                        <p className={twMerge(textStyle, "mb-5")}>{t("faq.third.cancel")}</p>
                        <p className={twMerge(textStyle, "mb-5")}>{t("faq.third.generally")}</p>
                        <p className={textStyle}>
                            {t("faq.third.change")}
                            <a href={`mailto:${supportLink}`} className="text-primary underline">
                                {supportLink}
                            </a>
                        </p>
                        <p className={twMerge(textStyle, "mb-5")}>{t("faq.third.subscriptions")}</p>
                    </>
                )
            }
        ],
        [firstList, t]
    );

    return (
        <section
            aria-label={t("choosePlan.header")}
            id={SectionId.FAQ}
            className="w-full py-20 px-9 mt-2 flex justify-between lg:px-4 lg:flex-col max-w-360">
            <Zoom triggerOnce>
                <h1 className="text-5xl font-semibold relative mb-8 lg:text-4xl lg:w-72">
                    <Trans
                        i18nKey="faq.header"
                        components={{ italic: <i className="font-thin" /> }}
                    />
                    <FaqArrowIcon className="absolute top-8 -right-20 lg:right-0 lg:top-5" />
                </h1>
                <ul className="w-3/5 lg:w-full">{faqData.map(renderQuestion)}</ul>
            </Zoom>
        </section>
    );
};
