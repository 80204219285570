import { FC, SVGProps } from "react";

export const PersonIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" width={20} {...props}>
        <path
            d="M10 8.33366C11.841 8.33366 13.3334 6.84127 13.3334 5.00033C13.3334 3.15938 11.841 1.66699 10 1.66699C8.15909 1.66699 6.66671 3.15938 6.66671 5.00033C6.66671 6.84127 8.15909 8.33366 10 8.33366Z"
            stroke="#FA4E04"
            strokeWidth="1.5"
        />
        <path
            d="M16.6667 14.584C16.6667 16.6548 16.6667 18.334 10 18.334C3.33337 18.334 3.33337 16.6548 3.33337 14.584C3.33337 12.5132 6.31837 10.834 10 10.834C13.6817 10.834 16.6667 12.5132 16.6667 14.584Z"
            stroke="#FA4E04"
            strokeWidth="1.5"
        />
    </svg>
);
