export enum SectionId {
    WHYWE = "why-we",
    HOME_PAGE = "home",
    FAQ = "faq",
    PRICING = "pricing",
    CONTACTS = "contacts",
    TERMS = "terms-of-service",
    PRIVACY_POLICY = "privacy-policy"
}
export enum LinkModule {
    FAQ = "faq",
    CHECKOUT = "checkout",
    PUBLIC = "public"
}

export enum ButtonType {
    ORANGE = "orange",
    BLACK = "black"
}
