import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Slide } from "react-awesome-reveal";
import { FooterLogoIcon } from "assets/icons/footer-logo.icon";
import { supportLink } from "constants/index";

interface Item {
    title: string;
    items: string[];
}

const itemArray = [
    {
        title: "support",
        items: [supportLink, "+1 (844) 3561179"]
    },
    {
        title: "address",
        items: ["3450 N. Triumph Blvd.,", "Suite 102, Lehi, Utah", "84043"]
    },
    {
        title: "menu",
        items: ["homepage", "terms", "privacy"]
    }
];

export const Footer: FC = () => {
    const { t } = useTranslation();

    const renderItem = (item: string) =>
        item === "homepage" || item === "terms" || item === "privacy" ? (
            <li
                key={item}
                className="text-base text-opacity-60 hover:text-white mb-2 transition-all">
                <a href={item === "homepage" ? "/" : "/" + item}>{t(`navigation.${item}`)}</a>
            </li>
        ) : (
            <li key={item} className="text-base text-opacity-60">
                {item}
            </li>
        );

    const renderList = ({ title, items }: Item) => (
        <div key={title}>
            <h2 className="text-xs text-opacity-60 mb-4">{t(`footer.${title}`)}</h2>
            <ul className="lg:mb-4">{items.map(renderItem)}</ul>
        </div>
    );

    return (
        <footer className="flex justify-center bg-cod-gray">
            <section
                aria-label="Footer"
                className="w-full max-w-360 py-20 px-9 mt-2 flex items-start justify-between lg:px-4 lg:py-8 lg:flex-col">
                <Slide triggerOnce>
                    <FooterLogoIcon className="lg:mb-4" />
                    {itemArray.map(renderList)}
                </Slide>
            </section>
        </footer>
    );
};
