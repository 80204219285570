import { FC, SVGProps } from "react";

export const SpeedIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" width={41} {...props}>
        <rect x="0.666656" width="40" height="40" rx="8" fill="#FA4E04" />
        <path
            d="M19.1167 23.5C19.5333 23.9167 20.0583 24.1127 20.6917 24.088C21.325 24.0633 21.7833 23.834 22.0667 23.4L27.6667 15L19.2667 20.6C18.8333 20.9 18.596 21.354 18.5547 21.962C18.5133 22.57 18.7007 23.0827 19.1167 23.5ZM13.7667 28C13.4 28 13.0627 27.9207 12.7547 27.762C12.4467 27.6033 12.2007 27.366 12.0167 27.05C11.5833 26.2667 11.25 25.4543 11.0167 24.613C10.7833 23.7717 10.6667 22.9007 10.6667 22C10.6667 20.6167 10.9293 19.3167 11.4547 18.1C11.98 16.8833 12.6923 15.825 13.5917 14.925C14.491 14.025 15.5493 13.3127 16.7667 12.788C17.984 12.2633 19.284 12.0007 20.6667 12C22.0333 12 23.3167 12.2583 24.5167 12.775C25.7167 13.2917 26.7667 13.996 27.6667 14.888C28.5667 15.78 29.2833 16.8217 29.8167 18.013C30.35 19.2043 30.625 20.4833 30.6417 21.85C30.6583 22.7667 30.5543 23.6627 30.3297 24.538C30.105 25.4133 29.759 26.2507 29.2917 27.05C29.1083 27.3667 28.8627 27.6043 28.5547 27.763C28.2467 27.9217 27.9087 28.0007 27.5407 28H13.7667Z"
            fill="white"
        />
    </svg>
);
