import { FC, SVGProps } from "react";

export const PowerIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg" width={47} {...props}>
        <g clipPath="url(#clip0_24_346)">
            <rect
                x="0.957977"
                y="0.0057373"
                width="45.2659"
                height="45.2659"
                rx="22.6329"
                fill="#FA4E04"
            />
            <g filter="url(#filter0_d_24_346)">
                <rect
                    x="6.99341"
                    y="6.04126"
                    width="33.195"
                    height="33.195"
                    rx="16.5975"
                    fill="#FFFCFF"
                    shapeRendering="crispEdges"
                />
                <g clipPath="url(#clip1_24_346)">
                    <path
                        d="M25.8541 16.1773V18.5809C26.4947 18.8604 27.0831 19.2599 27.5924 19.7691C28.6606 20.8374 29.2491 22.2588 29.2491 23.7706C29.2491 25.2825 28.6606 26.7027 27.5924 27.7721C26.5241 28.8404 25.1027 29.4289 23.5909 29.4289C22.079 29.4289 20.6588 28.8404 19.5894 27.7721C18.5211 26.7039 17.9326 25.2825 17.9326 23.7706C17.9326 22.2588 18.5211 20.8385 19.5894 19.7691C20.0986 19.2599 20.6871 18.8604 21.3276 18.5809V16.1773C19.5041 16.7209 17.9373 17.9028 16.9135 19.5067C15.8898 21.1106 15.4776 23.0294 15.7523 24.9122C16.027 26.7951 16.9703 28.5161 18.4096 29.7607C19.8489 31.0052 21.6881 31.6901 23.5909 31.6901C25.4936 31.6901 27.3328 31.0052 28.7721 29.7607C30.2114 28.5161 31.1547 26.7951 31.4294 24.9122C31.7041 23.0294 31.2919 21.1106 30.2682 19.5067C29.2444 17.9028 27.6776 16.7209 25.8541 16.1773ZM22.4592 13.5858H24.7225V22.639H22.4592V13.5858Z"
                        fill="#FA4E04"
                    />
                </g>
            </g>
        </g>
        <defs>
            <filter
                id="filter0_d_24_346"
                x="-0.550902"
                y="3.02354"
                width="48.2836"
                height="48.2836"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="4.52659" />
                <feGaussianBlur stdDeviation="3.77216" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.333333 0 0 0 0 0.109011 0 0 0 0 0.0125 0 0 0 0.6 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_24_346"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_24_346"
                    result="shape"
                />
            </filter>
            <clipPath id="clip0_24_346">
                <rect
                    x="0.957977"
                    y="0.0057373"
                    width="45.2659"
                    height="45.2659"
                    rx="22.6329"
                    fill="white"
                />
            </clipPath>
            <clipPath id="clip1_24_346">
                <rect
                    width="18.1063"
                    height="18.1063"
                    fill="white"
                    transform="translate(14.5377 13.5857)"
                />
            </clipPath>
        </defs>
    </svg>
);
