import { FC, SVGProps } from "react";

export const ContactsIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" width={40} {...props}>
        <rect width="40" height="40" rx="8" fill="#FA4E04" />
        <path
            d="M25.25 8.75H14.75C13.9546 8.75087 13.1921 9.06722 12.6296 9.62964C12.0672 10.1921 11.7509 10.9546 11.75 11.75V28.25C11.7509 29.0454 12.0672 29.8079 12.6296 30.3704C13.1921 30.9328 13.9546 31.2491 14.75 31.25H25.25C26.0454 31.2491 26.8079 30.9328 27.3704 30.3704C27.9328 29.8079 28.2491 29.0454 28.25 28.25V11.75C28.2491 10.9546 27.9328 10.1921 27.3704 9.62964C26.8079 9.06722 26.0454 8.75087 25.25 8.75ZM23.6319 21.3364C23.9867 21.7194 24.1602 22.2345 24.1189 22.7867C24.0378 23.8864 23.1992 24.7812 22.25 24.7812C21.3008 24.7812 20.4608 23.8864 20.3806 22.7867C20.3403 22.2289 20.5123 21.7128 20.8653 21.3336C21.2183 20.9544 21.7063 20.75 22.25 20.75C22.5085 20.7463 22.7648 20.7965 23.0028 20.8975C23.2407 20.9985 23.455 21.1479 23.6319 21.3364ZM17 11.75C17 11.5511 17.079 11.3603 17.2197 11.2197C17.3603 11.079 17.5511 11 17.75 11H22.25C22.4489 11 22.6397 11.079 22.7803 11.2197C22.921 11.3603 23 11.5511 23 11.75C23 11.9489 22.921 12.1397 22.7803 12.2803C22.6397 12.421 22.4489 12.5 22.25 12.5H17.75C17.5511 12.5 17.3603 12.421 17.2197 12.2803C17.079 12.1397 17 11.9489 17 11.75ZM25.8594 28.8045C25.8054 28.867 25.7383 28.917 25.6629 28.9507C25.5875 28.9845 25.5056 29.0013 25.423 29H19.077C18.9944 29.0013 18.9125 28.9845 18.8371 28.9507C18.7617 28.917 18.6946 28.867 18.6406 28.8045C18.5821 28.7344 18.54 28.6519 18.5176 28.5633C18.4952 28.4747 18.4931 28.3822 18.5113 28.2927C18.6636 27.4695 19.1384 26.7781 19.8847 26.293C20.5475 25.8622 21.3875 25.625 22.25 25.625C23.1125 25.625 23.9525 25.8622 24.6153 26.293C25.3616 26.7781 25.8364 27.4695 25.9888 28.2927C26.007 28.3822 26.0048 28.4747 25.9824 28.5633C25.96 28.6519 25.9179 28.7344 25.8594 28.8045Z"
            fill="white"
        />
    </svg>
);
