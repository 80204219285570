import { FC, SVGProps } from "react";

export const LetterIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" width={20} {...props}>
        <path
            d="M3.33329 16.6663C2.87496 16.6663 2.48274 16.5033 2.15663 16.1772C1.83051 15.8511 1.66718 15.4586 1.66663 14.9997V4.99967C1.66663 4.54134 1.82996 4.14912 2.15663 3.82301C2.48329 3.4969 2.87551 3.33356 3.33329 3.33301H16.6666C17.125 3.33301 17.5175 3.49634 17.8441 3.82301C18.1708 4.14967 18.3338 4.5419 18.3333 4.99967V14.9997C18.3333 15.458 18.1702 15.8505 17.8441 16.1772C17.518 16.5038 17.1255 16.6669 16.6666 16.6663H3.33329ZM9.99996 10.833L3.33329 6.66634V14.9997H16.6666V6.66634L9.99996 10.833ZM9.99996 9.16634L16.6666 4.99967H3.33329L9.99996 9.16634ZM3.33329 6.66634V4.99967V14.9997V6.66634Z"
            fill="#FA4E04"
        />
    </svg>
);
