import { FC, SVGProps } from "react";

export const MessageIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" width={20} {...props}>
        <path
            d="M6.66667 7.49967H13.3333M6.66667 10.833H11.6667M15 3.33301C15.663 3.33301 16.2989 3.5964 16.7678 4.06524C17.2366 4.53408 17.5 5.16997 17.5 5.83301V12.4997C17.5 13.1627 17.2366 13.7986 16.7678 14.2674C16.2989 14.7363 15.663 14.9997 15 14.9997H10.8333L6.66667 17.4997V14.9997H5C4.33696 14.9997 3.70107 14.7363 3.23223 14.2674C2.76339 13.7986 2.5 13.1627 2.5 12.4997V5.83301C2.5 5.16997 2.76339 4.53408 3.23223 4.06524C3.70107 3.5964 4.33696 3.33301 5 3.33301H15Z"
            stroke="#FA4E04"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
