import { FC, SVGProps } from "react";

export const XMarkServiceIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" width={24} {...props}>
        <path
            d="M7.9707 1.61282C8.96182 1.66789 9.34988 1.99063 9.92322 2.86801C11.7757 5.70293 13.2804 8.74406 15.1764 11.5614C16.2148 13.1043 17.9218 16.6299 19.6858 17.5119"
            stroke="#FA4E04"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <path
            d="M22.0002 2.35815C21.2316 2.43253 20.6216 2.9593 20.0309 3.42093C18.2845 4.78576 16.7357 6.36743 15.1026 7.85957C11.187 11.4371 6.84764 14.1166 2.30762 16.7994"
            stroke="#FA4E04"
            strokeWidth="3"
            strokeLinecap="round"
        />
    </svg>
);
