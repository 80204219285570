import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { supportLink } from "constants/index";

interface Props {
    className: string;
}

export const UsersInEU: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const list = t("privacy.purposesFulfilling").split(";");

    const renderLi = (item: string) => (
        <li className={className} key={item}>
            {item}
        </li>
    );

    return (
        <>
            <p className={twMerge("mt-2 mb-3", className)}>{t("privacy.committed")}</p>
            <p className={className}>{t("privacy.collect")}</p>
            <ul className={twMerge("list-disc list-inside mb-3", className)}>
                {list.map(renderLi)}
            </ul>
            <p className={className}>{t("privacy.exercise", { supportLink })}</p>
        </>
    );
};
