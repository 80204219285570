import { FC, SVGProps } from "react";

export const ProtectIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" width={41} {...props}>
        <rect x="0.333374" width="40" height="40" rx="8" fill="#FA4E04" />
        <g clipPath="url(#clip0_24_267)">
            <path
                d="M19.3334 10C18.0073 10 16.7355 10.5268 15.7978 11.4645C14.8602 12.4021 14.3334 13.6739 14.3334 15C14.3334 16.3261 14.8602 17.5979 15.7978 18.5355C16.7355 19.4732 18.0073 20 19.3334 20C20.6595 20 21.9312 19.4732 22.8689 18.5355C23.8066 17.5979 24.3334 16.3261 24.3334 15C24.3334 13.6739 23.8066 12.4021 22.8689 11.4645C21.9312 10.5268 20.6595 10 19.3334 10ZM19.3334 21C16.9384 21 14.7584 21.694 13.1554 22.672C12.3554 23.16 11.6714 23.736 11.1774 24.362C10.6914 24.976 10.3334 25.713 10.3334 26.5C10.3334 27.345 10.7444 28.011 11.3364 28.486C11.8964 28.936 12.6354 29.234 13.4204 29.442C14.9984 29.859 17.1044 30 19.3334 30L20.0184 29.995C20.1859 29.9927 20.3501 29.9484 20.496 29.8661C20.6418 29.7838 20.7647 29.6662 20.8533 29.524C20.9419 29.3819 20.9934 29.2198 21.003 29.0525C21.0126 28.8853 20.9801 28.7184 20.9084 28.567C20.5283 27.7647 20.3319 26.8878 20.3334 26C20.3334 24.748 20.7164 23.588 21.3704 22.627C21.4676 22.4842 21.5261 22.3186 21.5403 22.1465C21.5545 21.9743 21.5239 21.8014 21.4514 21.6446C21.3789 21.4878 21.267 21.3524 21.1266 21.2517C20.9862 21.151 20.8221 21.0884 20.6504 21.07C20.2204 21.024 19.7804 21 19.3334 21ZM26.6494 22.051C26.4442 21.9827 26.2225 21.9827 26.0174 22.051L23.0174 23.051C22.8182 23.1173 22.6449 23.2447 22.5221 23.415C22.3994 23.5854 22.3333 23.79 22.3334 24V25.671C22.3332 26.7406 22.7275 27.7727 23.4408 28.5698C24.1541 29.3668 25.1363 29.8729 26.1994 29.991C26.2894 30.001 26.3784 30.001 26.4674 29.991C27.5305 29.8729 28.5126 29.3668 29.2259 28.5698C29.9392 27.7727 30.3335 26.7406 30.3334 25.671V24C30.3334 23.79 30.2674 23.5854 30.1446 23.415C30.0218 23.2447 29.8486 23.1173 29.6494 23.051L26.6494 22.051Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_24_267">
                <rect width="24" height="24" fill="white" transform="translate(8.33337 8)" />
            </clipPath>
        </defs>
    </svg>
);
