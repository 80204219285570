import { FC } from "react";
import { twMerge } from "tailwind-merge";
import { ArrowTopIcon } from "assets/icons/arrow-top.icon";

interface Props {
    className?: string;
}

export const ButtonToTop: FC<Props> = ({ className }) => {
    const toTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (
        <div className="sticky w-full right-0 bottom-0 flex justify-end">
            <button
                onClick={toTop}
                className={twMerge(
                    "w-10 h-10 rounded-full bg-white-heaven flex justify-center items-center mb-16 hover:bg-opacity-90 lg:mb-2",
                    className
                )}>
                <ArrowTopIcon className="-rotate-45 animate-pulse" />
            </button>
        </div>
    );
};
