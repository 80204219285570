import { FC, SVGProps } from "react";

export const UnderlineIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 359 16" fill="none" xmlns="http://www.w3.org/2000/svg" width={359} {...props}>
        <path
            d="M2 6.34903C4.12489 4.93673 7.57516 4.59744 10.0397 4.22375C25.3173 1.90729 41.0988 2.22539 56.5472 2.14571C114.236 1.84815 171.935 2.09848 229.624 2.09848C260 4.00001 326.5 2.09854 356 10.5C367.867 13.8796 255.427 10.9343 208.129 12.2998C161.182 13.6552 114.289 14 67.3227 14"
            stroke="#FA4E04"
            strokeWidth="3"
            strokeLinecap="round"
        />
    </svg>
);
