import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Slide } from "react-awesome-reveal";
import { WhatOfferItem } from "./common/what-offer-item.component";
import { ContactsIcon } from "assets/icons/contacts.icon";
import { GeographicalIcon } from "assets/icons/geographical.icon";
import { ProtectIcon } from "assets/icons/protect.icon";
import { HashIcon } from "assets/icons/hash.icon";

const whatArray = [
    {
        header: "identity",
        subtext: "protection",
        icon: <ContactsIcon />
    },
    {
        header: "geographical",
        subtext: "cloaking",
        icon: <GeographicalIcon />
    },
    {
        header: "protect",
        subtext: "encryption",
        icon: <ProtectIcon />
    }
];

interface Item {
    icon: JSX.Element;
    header: string;
    subtext: string;
}

export const WhatMakes: FC = () => {
    const { t } = useTranslation();

    const renderLi = ({ icon, header, subtext }: Item) => (
        <WhatOfferItem
            icon={icon}
            header={t(`whatMakes.${header}`)}
            subtext={t(`whatMakes.${subtext}`)}
            key={header}
        />
    );

    return (
        <section
            aria-label="What Makes Go VPN #1"
            className="w-full bg-cod-gray py-20 px-9 mt-2 flex flex-col items-center justify-center lg:px-4">
            <Slide triggerOnce>
                <div className="max-w-360">
                    <div className="mb-10 flex lg:flex-col items-center">
                        <h1 className="text-5xl font-medium lg:text-center">
                            {t("whatMakes.header.firstPart")}
                            <Trans
                                i18nKey="whatMakes.header.secondPart"
                                components={{ italic: <i className="font-thin" /> }}
                            />
                        </h1>
                        <span className="text-5xl font-medium flex ml-3">
                            {t("whatMakes.header.thirdPart")}
                            <HashIcon className="ml-4 mr-2" /> 1
                        </span>
                    </div>

                    <ul className="flex justify-between lg:flex-col">{whatArray.map(renderLi)}</ul>
                </div>
            </Slide>
        </section>
    );
};
