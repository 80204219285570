import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { ButtonType } from "enums";

interface IProps extends PropsWithChildren {
    className?: string;
    classNameContainer?: string;
    type?: "button" | "submit";
    onClick?: () => void;
    arialLabel?: string;
    buttonType?: string;
}

export const Button = ({
    className,
    classNameContainer,
    type = "button",
    children,
    onClick,
    arialLabel,
    buttonType = ButtonType.BLACK
}: IProps) => (
    <div
        className={twMerge(
            buttonType === ButtonType.BLACK
                ? "bg-gradient-to-tr from-stone-900 via-stone-700 to-stone-900 p-[1px] rounded-full"
                : "lg:w-full",
            classNameContainer
        )}>
        <button
            aria-label={arialLabel}
            type={type}
            className={twMerge(
                "w-full h-11 px-6 rounded-full text-base text-nowrap font-normal leading-5 cursor-pointer duration-300 flex items-center",
                buttonType === ButtonType.ORANGE &&
                    "bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-amber-300 via-orange-500 to-orange-600 shadow-2xl shadow-orange-600 border border-white border-opacity-30",
                buttonType === ButtonType.BLACK && "bg-gradient-to-b from-stone-900 to-main-black",
                className
            )}
            onClick={onClick}>
            {children}
        </button>
    </div>
);
