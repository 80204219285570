import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    className: string;
}

export const PersonalInfo: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const list = t("privacy.transactions").split(";");

    const renderLi = (item: string) => (
        <li className={className} key={item}>
            {item}
        </li>
    );

    return (
        <>
            <p className={twMerge("my-3", className)}>{t("privacy.personal")}</p>
            <p className={twMerge("mb-3", className)}>{t("privacy.payment")}</p>
            <p className={className}>{t("privacy.email")}</p>
            <ul className={twMerge("list-disc list-inside mb-3", className)}>
                {list.map(renderLi)}
            </ul>
            <p className={className}>{t("privacy.purposes")}</p>
        </>
    );
};
