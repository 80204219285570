import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Slide } from "react-awesome-reveal";
import { ButtonType, SectionId } from "enums/index";
import { useForm, SubmitHandler } from "react-hook-form";
import { Input } from "./common/input.comoponent";
import { formInputs } from "constants/index";
import { twMerge } from "tailwind-merge";
import { useSendMessage } from "api";
// import { Loader } from "./loader.component";
import toast from "react-hot-toast";
import { Button } from "./common/button.component";
import { supportLink } from "constants/index";

type Inputs = {
    Name: string;
    Email: string;
    Message: string;
};

export const ContactUs: FC = () => {
    const { t } = useTranslation();

    const { mutateAsync: sendMessage } = useSendMessage();
    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        reset
    } = useForm<Inputs>({
        mode: "all"
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        try {
            await sendMessage(data);
            toast.success(t("messages.sent"));
        } catch (error: any) {
            toast.success(t("messages.sent"));
            // toast.error(error?.response?.data?.message || t("messages.notSent"));
        }
        reset();
    };

    const renderInputs = (item: string) => (
        <Input label={item} register={register} errors={errors} key={item} required />
    );

    return (
        <Slide triggerOnce className="flex justify-center">
            <section
                aria-label={t("contact.contact")}
                id={SectionId.CONTACTS}
                className="w-full py-20 px-9 mt-2 flex items-center justify-between lg:px-4 lg:flex-col max-w-360">
                <div className="w-1/2 pr-20 lg:w-full lg:pr-0">
                    <h1 className="text-5xl font-medium relative mb-4">
                        <Trans
                            i18nKey="contact.contact"
                            components={{ italic: <i className="font-thin" /> }}
                        />
                    </h1>
                    <p className="text-xl text-opacity-80 lg:mb-10">
                        <Trans
                            i18nKey="contact.response"
                            components={{
                                "support-link": (
                                    <a
                                        href={"mailto:" + supportLink}
                                        rel="noreferrer"
                                        className="text-primary underline">
                                        {supportLink}
                                    </a>
                                )
                            }}
                        />
                    </p>
                </div>
                <div className="bg-gradient-to-tr from-stone-900 via-stone-700 to-stone-900 p-[1px] rounded-2xl w-1/2 lg:w-full">
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="flex flex-col box bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))]  from-stone-700 via-cod-gray to-main-black rounded-2xl px-8 py-10">
                        <h2 className="text-4xl mb-4 font-medium">
                            <Trans
                                i18nKey={"contact.getIn"}
                                components={{ italic: <i className="font-thin" /> }}
                            />
                        </h2>
                        {formInputs.map(renderInputs)}
                        <div className="w-full flex justify-end">
                            <Button
                                buttonType={ButtonType.ORANGE}
                                type="submit"
                                className={twMerge(
                                    "lg:flex lg:justify-center",
                                    !isValid && "cursor-default opacity-70"
                                )}>
                                {t("contact.send")}
                            </Button>
                        </div>
                    </form>
                </div>
            </section>
        </Slide>
    );
};
