import { FC, SVGProps } from "react";

export const HashIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 41 50" fill="none" xmlns="http://www.w3.org/2000/svg" width={41} {...props}>
        <path
            d="M19.6737 2.76758C17.558 5.11833 16.9442 8.85351 16.3296 11.8036C14.7707 19.2865 13.1005 27.0019 13.014 34.6712C12.986 37.1572 12.8822 39.6557 13.2559 42.0851C13.3729 42.8454 13.5263 43.7521 13.5263 44.5184"
            stroke="#FA4E04"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <path
            d="M34.0175 2C33.9618 3.22471 33.5565 4.34789 33.2064 5.51481C31.7654 10.318 30.941 15.3501 30.09 20.2855C28.5395 29.2789 29.0584 38.5535 29.9193 47.5929"
            stroke="#FA4E04"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <path
            d="M2 20.1854C4.57284 20.1854 6.82596 18.6283 9.2573 17.937C13.1715 16.8242 17.3097 16.348 21.2959 15.5606C26.6874 14.4956 32.3609 14.5503 37.8596 14.5503"
            stroke="#FA4E04"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <path
            d="M4.81757 35.8108C7.31998 34.6847 10.4209 34.4584 13.0994 33.9609C18.272 33.0003 23.3266 31.9659 28.6101 31.741C32.1957 31.5885 35.8058 31.7126 39.3965 31.7126"
            stroke="#FA4E04"
            strokeWidth="3"
            strokeLinecap="round"
        />
    </svg>
);
