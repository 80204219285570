import { LogoIcon } from "assets/icons/logo.icon";
import { FC } from "react";
import { Link } from "react-router-dom";
import { HOME_PATH } from "router/constants";
import { twMerge } from "tailwind-merge";

interface Props {
    className?: string;
}

export const Logo: FC<Props> = ({ className }) => (
    <Link to={HOME_PATH} className={twMerge("flex items-center", className)}>
        <LogoIcon />
        <span className="font-medium text-lg ml-3">GoVPN</span>
    </Link>
);
