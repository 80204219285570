import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Slide } from "react-awesome-reveal";
import { twMerge } from "tailwind-merge";
import { supportLink } from "constants/index";

import { InfoWrapper } from "components/common/info-wrapper.component";
import { Contents } from "components/privacy/contents.component";
import { GeneralInfo } from "components/privacy/general-info.component";
import { PersonalInfo } from "components/privacy/personal-info.component";
import { Aggregate } from "components/privacy/aggregate.component";
import { Identifiers } from "components/privacy/identifiers.component";
import { UsersInEU } from "components/privacy/usersIn-eu.component";

interface LiItem {
    header: string;
    content: JSX.Element;
}

export const PrivacyPolicy: FC = () => {
    const { t } = useTranslation();

    const defaultStyles = "text-base text-opacity-60 font-normal leading-6";

    const termsItems = [
        {
            header: "contents",
            content: <Contents className={defaultStyles} />
        },
        {
            header: "generalInfo",
            content: <GeneralInfo className={defaultStyles} />
        },
        {
            header: "personalInfo",
            content: <PersonalInfo className={defaultStyles} />
        },
        {
            header: "aggregate",
            content: <Aggregate className={defaultStyles} />
        },
        {
            header: "identifiers",
            content: <Identifiers className={defaultStyles} />
        },
        {
            header: "usersInEU",
            content: <UsersInEU className={defaultStyles} />
        },
        {
            header: "changesTo",
            content: <p className={twMerge("mt-3", defaultStyles)}>{t("privacy.time")}</p>
        },
        {
            header: "howContact",
            content: (
                <>
                    <p className={twMerge("mt-3 mb-6", defaultStyles)}>{t("privacy.questions")}</p>
                    <a
                        href={"mailto:" + supportLink}
                        rel="noreferrer"
                        className="text-base font-normal text-primary underline">
                        {supportLink}
                    </a>
                </>
            )
        }
    ];

    const renderLi = ({ header, content }: LiItem) => (
        <Slide triggerOnce key={header}>
            <li className="font-bold text-xl mb-3">
                {t(`privacy.${header}`)}
                {content}
            </li>
        </Slide>
    );

    return (
        <InfoWrapper header={t("privacy.header")}>
            <p className={twMerge(defaultStyles, "mb-4")}>{t("privacy.understand")}</p>
            <p className={twMerge(defaultStyles, "mb-4")}>{t("privacy.principle")}</p>
            <p className={twMerge(defaultStyles, "mb-4")}>{t("privacy.privacy")}</p>
            <ul className="list-none list-inside">{termsItems.map(renderLi)}</ul>
        </InfoWrapper>
    );
};
