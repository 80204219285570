import { FC, SVGProps } from "react";

export const GeoIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" width={41} {...props}>
        <rect x="0.333374" width="40" height="40" rx="8" fill="#FA4E04" />
        <path
            d="M20.3334 19.5C19.6703 19.5 19.0344 19.2366 18.5656 18.7678C18.0968 18.2989 17.8334 17.663 17.8334 17C17.8334 16.337 18.0968 15.7011 18.5656 15.2322C19.0344 14.7634 19.6703 14.5 20.3334 14.5C20.9964 14.5 21.6323 14.7634 22.1011 15.2322C22.57 15.7011 22.8334 16.337 22.8334 17C22.8334 17.3283 22.7687 17.6534 22.6431 17.9567C22.5174 18.26 22.3333 18.5356 22.1011 18.7678C21.869 18.9999 21.5934 19.1841 21.2901 19.3097C20.9868 19.4353 20.6617 19.5 20.3334 19.5ZM20.3334 10C18.4769 10 16.6964 10.7375 15.3836 12.0503C14.0709 13.363 13.3334 15.1435 13.3334 17C13.3334 22.25 20.3334 30 20.3334 30C20.3334 30 27.3334 22.25 27.3334 17C27.3334 15.1435 26.5959 13.363 25.2831 12.0503C23.9704 10.7375 22.1899 10 20.3334 10Z"
            fill="white"
        />
    </svg>
);
