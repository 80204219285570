import { FC } from "react";
import { Outlet } from "react-router-dom";
import { Navbar } from "./navbar.component";
import { ContactUs } from "./contact-us.component";
import { Footer } from "./footer.component";

export const MainLayout: FC = () => (
    <>
        <Navbar />
        <Outlet />
        <ContactUs />
        <Footer />
    </>
);
