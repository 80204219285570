import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { supportLink } from "constants/index";

interface Props {
    className: string;
}

export const Refund: FC<Props> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <>
            <p className={twMerge(className, "mt-3 mb-4")}>{t("terms.cancel")}</p>
            <p className={twMerge(className, "mb-4")}>{t("terms.beyond")}</p>
            <p className={className}>
                {t("terms.generally")}
                <a className="text-primary underline" href={"mailto:" + supportLink}>
                    {supportLink}
                </a>
            </p>
        </>
    );
};
