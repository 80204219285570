import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Zoom } from "react-awesome-reveal";
import { Button } from "./common/button.component";
import { ButtonType } from "enums/index";
import { HeaderItem } from "./common/header-item.component";
import MainImage from "assets/images/header-main-image.png";
import { OffItem } from "./common/off-item.component";
import { UnderlineIcon } from "assets/icons/underline.icon";
import { scrollIntoElementById } from "utils/scroll-into-element-by-id";
import { SectionId } from "enums/index";

export const Header: FC = () => {
    const { t } = useTranslation();

    const handleLinkClick = () => {
        scrollIntoElementById(SectionId.PRICING);
    };

    return (
        <header className="overflow-hidden flex flex-col items-center h-screen min-h-175 min2xl:h-202 lg:h-175 bg-header bg-no-repeat bg-cover lg:bg-contain">
            <Zoom triggerOnce>
                <section className="relative w-screen h-screen pt-64 px-9 max-w-360 min2xl:h-202 lg:flex lg:flex-col lg:items-center lg:pt-28 lg:px-4 lg:h-175">
                    <h1 className="text-7.5xl font-medium leading-[104px] relative z-30 lg:text-[40px] lg:text-center lg:leading-[52px]">
                        <Trans i18nKey="myKey">
                            {t("header.unlock.part1")}
                            <i className="font-extralight relative">
                                <span className="z-20 relative">{t("header.unlock.text")}</span>
                                <UnderlineIcon className="absolute bottom-0 right-0 z-0 lg:w-full" />
                            </i>
                            {t("header.unlock.part2")}
                        </Trans>
                    </h1>
                    <p className="mb-10 text-opacity-60 font-normal relative z-30 lg:text-center lg:w-72">
                        {t("header.stayPrivate")}
                    </p>
                    <Button
                        buttonType={ButtonType.ORANGE}
                        className="w-fit relative z-30 lg:w-full lg:flex lg:justify-center"
                        onClick={handleLinkClick}>
                        {t("header.started")}
                    </Button>
                    <div className="absolute right-36 bottom-0 lg:flex lg:justify-center lg:w-full lg:right-0">
                        <div className="relative">
                            <HeaderItem
                                className="w-52 h-48 absolute -left-28 bottom-28 z-20 rotate-[7.5deg] lg:w-32 lg:h-28 lg:-left-1"
                                download={true}
                            />
                            <img
                                className="relative z-10 lg:w-80"
                                src={MainImage}
                                alt="dark-something"
                                width={520}
                            />
                            <HeaderItem
                                className="w-44 h-40 absolute right-0 -top-7 z-0 -rotate-[7.5deg] lg:w-28 lg:h-24"
                                download={false}
                            />
                            <OffItem className="w-fit absolute bottom-2 right-0 z-20 -rotate-[7.5deg]" />
                        </div>
                    </div>
                </section>
            </Zoom>
        </header>
    );
};
