import { FC, SVGProps } from "react";

export const GeographicalIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" width={41} {...props}>
        <rect x="0.666656" width="40" height="40" rx="8" fill="#FA4E04" />
        <path
            d="M23.6667 12.343C23.6667 13.465 22.2907 15.46 21.4027 16.631C21.3175 16.746 21.2065 16.8394 21.0787 16.9038C20.9509 16.9682 20.8098 17.0017 20.6667 17.0017C20.5235 17.0017 20.3824 16.9682 20.2546 16.9038C20.1268 16.8394 20.0159 16.746 19.9307 16.631C19.4825 16.0414 19.0607 15.4321 18.6667 14.805C18.1167 13.91 17.6667 12.985 17.6667 12.343C17.6667 10.686 19.0097 9 20.6667 9C22.3237 9 23.6667 10.686 23.6667 12.343ZM19.7957 13.221C20.0493 13.4038 20.354 13.5021 20.6667 13.5021C20.9793 13.5021 21.284 13.4038 21.5377 13.221C21.7965 13.0364 21.9899 12.7744 22.0902 12.4727C22.1904 12.171 22.1922 11.8453 22.0953 11.5425C21.9984 11.2398 21.8078 10.9756 21.551 10.7882C21.2942 10.6008 20.9845 10.4998 20.6667 10.4998C20.3488 10.4998 20.0391 10.6008 19.7823 10.7882C19.5255 10.9756 19.335 11.2398 19.2381 11.5425C19.1411 11.8453 19.1429 12.171 19.2432 12.4727C19.3434 12.7744 19.5368 13.0364 19.7957 13.221ZM14.7447 19.823L18.4247 16.26C18.1328 15.8412 17.8579 15.4109 17.6007 14.97L11.6567 20.725C11.3435 21.028 11.0946 21.3908 10.9245 21.7919C10.7544 22.1931 10.6667 22.6243 10.6667 23.06V26.75C10.6667 27.3467 10.9037 27.919 11.3257 28.341C11.7476 28.7629 12.3199 29 12.9167 29H28.4167C29.0134 29 29.5857 28.7629 30.0076 28.341C30.4296 27.919 30.6667 27.3467 30.6667 26.75V23.06C30.6667 22.6241 30.5791 22.1927 30.409 21.7914C30.2389 21.3901 29.9899 21.0271 29.6767 20.724L23.7327 14.971C23.4751 15.4116 23.1999 15.8416 22.9077 16.26L24.9897 18.275C24.3028 18.363 23.6263 18.5181 22.9697 18.738C22.2877 18.961 21.5857 19.249 20.9297 19.52C20.5417 19.679 20.1697 19.832 19.8287 19.96C18.8457 20.33 17.9507 20.573 17.0467 20.543C16.3367 20.518 15.5797 20.323 14.7447 19.823Z"
            fill="white"
        />
    </svg>
);
