import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    className: string;
}

export const Identifiers: FC<Props> = ({ className }) => {
    const { t } = useTranslation();
    const link = "https://optout.networkadvertising.org/?c=1";
    const apple = "https://www.apple.com/legal/privacy/data/en/apple-advertising/";
    const google = "https://support.google.com/android/answer/3118621?hl=en";

    return (
        <>
            <h2 className="font-bold text-base mt-3 mb-2">{t("privacy.whatCookie")}</h2>
            <p className={twMerge("mb-3", className)}>{t("privacy.informationAbout")}</p>
            <h2 className="font-bold text-base mb-2">{t("privacy.disabling")}</h2>
            <p className={twMerge("mb-3", className)}>{t("privacy.preferences")}</p>
            <h2 className="font-bold text-base mb-2">{t("privacy.govpnCookies")}</h2>
            <p className={twMerge("mb-3", className)}>{t("privacy.language")}</p>
            <h2 className="font-bold text-base mb-2">{t("privacy.tpCookies")}</h2>
            <p className={twMerge("mb-3", className)}>
                <Trans
                    i18nKey="privacy.tpServices"
                    components={{
                        h: (
                            <a
                                aria-label="Network Advertising Link"
                                href={link}
                                target="_blank"
                                className="text-primary underline"
                                rel="noreferrer noopener"
                            />
                        )
                    }}
                />
            </p>
            <h2 className="font-bold text-base mb-2">{t("privacy.mobileIdentifiers")}</h2>
            <p className={twMerge("mb-3", className)}>{t("privacy.provided")}</p>
            <h2 className="font-bold text-base mb-2">{t("privacy.resetting")}</h2>
            <p className={twMerge("mb-3", className)}>
                <Trans
                    i18nKey="privacy.disable"
                    components={{
                        apple: (
                            <a
                                aria-label="Apple Link"
                                href={apple}
                                target="_blank"
                                className="text-primary underline"
                                rel="noreferrer noopener"
                            />
                        ),
                        google: (
                            <a
                                aria-label="Google Link"
                                href={google}
                                target="_blank"
                                className="text-primary underline"
                                rel="noreferrer noopener"
                            />
                        )
                    }}
                />
            </p>
            <h2 className="font-bold text-base mb-2">{t("privacy.tpWebsites")}</h2>
            <p className={twMerge("mb-3", className)}>{t("privacy.external")}</p>
            <h2 className="font-bold text-base mb-2">{t("privacy.restrictions")}</h2>
            <p className={twMerge("mb-3", className)}>{t("privacy.usingSite")}</p>
            <p className={className}>{t("privacy.adults")}</p>
        </>
    );
};
