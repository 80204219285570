import { FC, useEffect } from "react";

// import { IframeContainer } from "components/common/iframe-container.component";
// import { LinkModule } from "enums";
// import { generateLink } from "utils/generate-link";
import { ServiceNotAvailable } from "components/service-not-available.component";

export const LoginPage: FC = () => {
    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, []);

    // const link = generateLink({ module: LinkModule.CHECKOUT });

    // return <IframeContainer title="Login" link={link} id="login" />;
    return <ServiceNotAvailable />;
};
