import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { Graph1Icon } from "assets/icons/graph-1.icon";
import { Graph2Icon } from "assets/icons/graph-2.icon";
import { DownloadIcon } from "assets/icons/download.icon";
import { UploadIcon } from "assets/icons/upload.icon";

interface Props {
    className?: string;
    download: boolean;
}

export const HeaderItem: FC<Props> = ({ className, download }) => {
    const { t } = useTranslation();
    const graphIconStyle = "absolute bottom-0 left-0 w-full p-5 lg:p-4";

    return (
        <div
            className={twMerge(
                "relative bg-gradient-to-tr from-stone-900 via-stone-700 to-stone-900 p-[1px] rounded-2xl",
                className
            )}>
            <div className="bg-gradient-to-b from-stone-900 to-main-black h-full rounded-2xl p-5 overflow-hidden lg:p-4">
                <div className="flex">
                    <div
                        className={twMerge(
                            "w-8 h-8 flex justify-center rounded-full bg-primary",
                            download ? "lg:w-5 lg:h-5" : "lg:w-4 lg:h-4"
                        )}>
                        {download ? (
                            <DownloadIcon className="lg:w-2.5" />
                        ) : (
                            <UploadIcon className="lg:w-2.25" />
                        )}
                    </div>
                    <div className="ml-2">
                        <h1
                            className={twMerge(
                                "text-sm ",
                                download
                                    ? "lg:text-2 lg:leading-none"
                                    : "lg:text-1.75 lg:leading-none"
                            )}>
                            {download ? t("header.download") : t("header.upload")}
                        </h1>
                        <p
                            className={twMerge(
                                "text-2xl font-semibold",
                                download ? "lg:text-sm" : "lg:text-3.25"
                            )}>
                            {download ? "100.5 " : "76.3 "}
                            <span
                                className={twMerge(
                                    "text-sm text-opacity-30 font-normal",
                                    download ? "lg:text-2.25" : "lg:text-2"
                                )}>
                                Mbs
                            </span>
                        </p>
                    </div>
                </div>
                {download ? (
                    <Graph1Icon className={graphIconStyle} />
                ) : (
                    <Graph2Icon className={graphIconStyle} />
                )}
            </div>
        </div>
    );
};
