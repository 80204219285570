import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { UseFormRegister, FieldErrors } from "react-hook-form";
import { MessageIcon } from "assets/icons/message.icon";
import { PersonIcon } from "assets/icons/person.icon";
import { LetterIcon } from "assets/icons/letter.icon";

interface IFormValues {
    Name: string;
    Email: string;
    Message: string;
}

type InputProps = {
    label: string;
    register: UseFormRegister<IFormValues>;
    required: boolean;
    className?: string;
    errors: FieldErrors<IFormValues>;
};

export const Input = ({ register, required, label, className, errors }: InputProps) => {
    const { t } = useTranslation();
    const defaultStyle =
        "bg-orange-bg bg-no-repeat rounded-lg font-outfit text-base p-3 text-white-heaven outline-none w-full mb-3 border border-white border-opacity-5";
    const defaultInputStyle = "bg-transparent outline-none w-full pt-3 px-3";

    return (
        <div className={twMerge("relative w-full", className)}>
            {errors[label as keyof IFormValues] && (
                <span className="font-outfit text-xs absolute right-4 top-2 text-red-500">
                    {errors[label as keyof IFormValues]?.message}
                </span>
            )}
            <label className="absolute text-xs text-white-heaven text-opacity-60 left-10 top-2">
                {t(label)}
            </label>
            {label === t("contact.message") ? (
                <div className={twMerge(defaultStyle, "flex items-start")}>
                    <MessageIcon />
                    <textarea
                        {...register(t(label), {
                            required,
                            minLength: { value: 10, message: t("contact.min10") }
                        })}
                        className={twMerge(defaultInputStyle, "h-24 resize-none")}
                    />
                </div>
            ) : label === t("contact.email") ? (
                <div className={twMerge(defaultStyle, "flex items-start")}>
                    <LetterIcon />
                    <input
                        {...register(t(label), {
                            required,
                            pattern: {
                                value: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                message: t("contact.validEmail")
                            }
                        })}
                        className={defaultInputStyle}
                    />
                </div>
            ) : (
                <div className={twMerge(defaultStyle, "flex items-start")}>
                    <PersonIcon />
                    <input
                        {...register(t(label), {
                            required,
                            minLength: { value: 2, message: t("contact.min2") }
                        })}
                        className={defaultInputStyle}
                    />
                </div>
            )}
        </div>
    );
};
