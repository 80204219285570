import { FC, SVGProps } from "react";

export const FaqArrowIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width={138}
        viewBox="0 0 138 122"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M25.0241 21.6946C31.1526 17.603 46.9105 19.2219 51.4834 20.1437C58.3103 21.5198 78.2556 26.8877 78.2202 31.6257C78.201 34.1824 32.358 39.2586 19.7961 61.6184C-0.51292 97.7678 94.9751 37.4701 132.533 61.3369"
            stroke="#FA4E04"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <path
            d="M111.017 46.717C119.213 49.6618 133.798 62.0008 133.798 62.0008C133.798 62.0008 119.908 64.976 111.222 76.2277"
            stroke="#FA4E04"
            strokeWidth="3"
            strokeLinecap="round"
        />
    </svg>
);
