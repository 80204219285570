import { LOGIN_PATH } from "router/constants";
import { ButtonType, SectionId } from "enums";
import { NavigationLinkProps, ManageAccountButtonsProps } from "types";

export const navigationLinkContent: NavigationLinkProps[] = [
    {
        name: "navigation.why",
        id: SectionId.WHYWE
    },
    {
        name: "navigation.pricing",
        id: SectionId.PRICING
    },
    {
        name: "navigation.faq",
        id: SectionId.FAQ
    },
    {
        name: "navigation.contact",
        id: SectionId.CONTACTS
    }
];

export const manageAccountButtons: ManageAccountButtonsProps[] = [
    {
        name: "navigation.login",
        path: LOGIN_PATH,
        type: ButtonType.BLACK
    }
];

export const formInputs = ["Your Name", "Your Email", "Your Message"];

export const microsoftLink = "https://privacy.microsoft.com/en-us/PrivacyStatement";
export const sentryPrivacy = "https://sentry.io/privacy/";
export const firebasePrivacy = "https://firebase.google.com/";
export const appleCash = "https://support.apple.com/en-us/108971";
export const apple = "https://www.apple.com/legal/privacy/en-ww/";
export const google = "https://policies.google.com/privacy";

export const supportLink = `support@${window.location.hostname}`;
