import { FC, SVGProps } from "react";

export const Graph1Icon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 180 88" fill="none" xmlns="http://www.w3.org/2000/svg" width={180} {...props}>
        <mask id="mask0_24_174" maskUnits="userSpaceOnUse" x="0" y="0" width="180" height="88">
            <rect
                x="0.450226"
                y="0.846924"
                width="179.379"
                height="86.9441"
                rx="6.52289"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_24_174)">
            <path
                opacity="0.5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.450241 63.623C0.450241 63.623 7.32384 60.7547 29.7073 46.6838C52.0908 32.6129 53.1335 19.0294 60.4032 19.0294C67.673 19.0294 79.3142 42.0427 90.1399 42.0427C100.966 42.0427 102.947 27.5803 120.356 29.5436C137.766 31.5069 141.145 45.0185 149.613 48.7497C158.082 52.481 179.83 34.1897 179.83 34.1897V88.0573H0.450241L0.450241 63.623Z"
                fill="url(#paint0_linear_24_174)"
                fillOpacity="0.5"
            />
            <path
                d="M0.450241 63.5428C0.450241 63.5428 7.32384 60.6796 29.7073 46.634C52.0908 32.5884 53.1335 19.0294 60.4032 19.0294C67.673 19.0294 79.3142 42.0013 90.1399 42.0013C100.966 42.0013 102.947 27.5649 120.356 29.5247C137.766 31.4845 141.145 44.9717 149.613 48.6962C158.082 52.4207 179.83 34.1624 179.83 34.1624"
                stroke="#FA4E04"
                strokeWidth="1.95687"
                strokeLinecap="round"
            />
            <circle
                cx="59.9383"
                cy="19.1508"
                r="4.24701"
                fill="#FA4E04"
                stroke="#FFFCFF"
                strokeWidth="1.17241"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_24_174"
                x1="179.83"
                y1="88.0573"
                x2="179.83"
                y2="19.0294"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="white" stopOpacity="0.1" />
                <stop offset="1" stopColor="#FA4E04" />
            </linearGradient>
        </defs>
    </svg>
);
