import { FC, SVGProps } from "react";

export const ArrowIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 55 35" fill="none" xmlns="http://www.w3.org/2000/svg" width={55} {...props}>
        <g opacity="0.8">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.9264 16.5892C23.1578 16.8565 23.2736 17.2048 23.2482 17.5574C23.2229 17.91 23.0585 18.2382 22.7912 18.4696L14.7271 25.452C14.5953 25.5702 14.4412 25.661 14.274 25.719C14.1067 25.777 13.9296 25.8012 13.7529 25.79C13.5762 25.7788 13.4035 25.7326 13.2448 25.654C13.0862 25.5754 12.9448 25.4659 12.8289 25.3321C12.713 25.1982 12.625 25.0427 12.5699 24.8744C12.5147 24.7062 12.4937 24.5286 12.5079 24.3521C12.5221 24.1757 12.5714 24.0038 12.6527 23.8466C12.7341 23.6893 12.846 23.5499 12.9818 23.4363L20.0381 17.3266L13.9284 10.2703C13.7042 10.0021 13.5943 9.65648 13.6224 9.308C13.6505 8.95952 13.8143 8.63602 14.0786 8.40718C14.3429 8.17833 14.6866 8.06245 15.0355 8.08449C15.3844 8.10653 15.7107 8.26473 15.9441 8.52502L22.9264 16.5892Z"
                fill="#FFFCFF"
            />
        </g>
        <g opacity="0.2">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.0502 16.5892C42.2816 16.8565 42.3973 17.2048 42.372 17.5574C42.3466 17.91 42.1823 18.2382 41.915 18.4696L33.8509 25.452C33.7191 25.5702 33.565 25.661 33.3978 25.719C33.2305 25.777 33.0533 25.8012 32.8767 25.79C32.7 25.7788 32.5273 25.7326 32.3686 25.654C32.21 25.5754 32.0686 25.4659 31.9527 25.3321C31.8368 25.1982 31.7488 25.0427 31.6936 24.8744C31.6385 24.7062 31.6175 24.5286 31.6317 24.3521C31.6459 24.1757 31.6952 24.0038 31.7765 23.8466C31.8579 23.6893 31.9697 23.5499 32.1056 23.4363L39.1619 17.3266L33.0522 10.2703C32.828 10.0021 32.7181 9.65648 32.7462 9.308C32.7743 8.95952 32.9381 8.63602 33.2024 8.40718C33.4667 8.17833 33.8103 8.06245 34.1593 8.08449C34.5082 8.10653 34.8345 8.26473 35.0679 8.52502L42.0502 16.5892Z"
                fill="#FFFCFF"
            />
        </g>
    </svg>
);
