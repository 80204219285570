import { FC, SVGProps } from "react";

export const Graph2Icon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 154 75" fill="none" xmlns="http://www.w3.org/2000/svg" width={154} {...props}>
        <mask id="mask0_24_152" maskUnits="userSpaceOnUse" x="0" y="0" width="154" height="75">
            <rect x="0.565826" y="0.584595" width="153" height="74.1582" rx="5.56364" fill="white" />
        </mask>
        <g mask="url(#mask0_24_152)">
            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M153.566 54.1288C153.566 54.1288 147.703 51.6823 128.611 39.6807C109.52 27.679 108.63 16.0931 102.429 16.0931C96.2288 16.0931 86.2995 35.7221 77.0658 35.7221C67.8321 35.7221 66.1423 23.3865 51.2931 25.0611C36.4439 26.7357 33.5618 38.2602 26.3386 41.4428C19.1153 44.6253 0.565835 29.024 0.565835 29.024L0.565835 74.9699L153.566 74.9699L153.566 54.1288Z" fill="url(#paint0_linear_24_152)" fillOpacity="0.5" />
            <path d="M153.566 54.0604C153.566 54.0604 147.703 51.6183 128.611 39.6382C109.52 27.6582 108.63 16.0931 102.429 16.0931C96.2288 16.0931 86.2995 35.6868 77.0658 35.6868C67.8321 35.6868 66.1423 23.3734 51.2931 25.045C36.4439 26.7165 33.5618 38.2203 26.3386 41.3971C19.1153 44.5739 0.565834 29.0007 0.565834 29.0007" stroke="#FA4E04" strokeWidth="1.66909" strokeLinecap="round" />
            <circle cx="51.3057" cy="23.8201" r="3.62245" fill="#FA4E04" stroke="#FFFCFF" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_24_152" x1="0.565835" y1="74.9699" x2="0.565836" y2="16.0931" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" stopOpacity="0.1" />
                <stop offset="1" stopColor="#FA4E04" />
            </linearGradient>
        </defs>
    </svg>

);
