import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { Button } from "./button.component";
import { ButtonType } from "enums/index";

interface Props {
    className?: string;
    image: string;
    days: number;
    price: number;
    onButtonClick: () => void;
}

export const PricingItem: FC<Props> = ({ className, image, days, price, onButtonClick }) => {
    const { t } = useTranslation();

    return (
        <div
            className={
                "bg-gradient-to-tr from-stone-900 via-stone-700 to-stone-900 p-[1px] rounded-2xl"
            }>
            <li
                className={twMerge(
                    "bg-main-black rounded-2xl min-w-72 lg:mb-4 lg:last:mb-0 flex flex-col items-center",
                    className
                )}>
                <img src={image} alt="Pricing random item" className="flex" />
                <div className="px-4 py-8 w-full">
                    <p className="flex justify-between items-center text-sm mb-8">
                        {t("choosePlan.days", { days })}
                        <span className="text-2xl font-semibold">${price}</span>
                    </p>
                    <Button
                        className="flex justify-center"
                        buttonType={days === 180 ? ButtonType.ORANGE : ButtonType.BLACK}
                        onClick={onButtonClick}>
                        {t("choosePlan.purchase")}
                    </Button>
                </div>
            </li>
        </div>
    );
};
